import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";
import Splide from "@splidejs/splide";

var html_classes = document.documentElement.classList;

html_classes.remove("no-js");
html_classes.add("js");

export function init() {
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
	new FloatLabels(".js-float-wrap");
	setupSlider();
	setupBannerSlidingDoor();
	setupBurgerMenu();
}

window.addEventListener("DOMContentLoaded", init);

// add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
export function lazyBackgrounds(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
}


export function setupSlider(){
	var elms = document.getElementsByClassName("slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "fade",
			rewind    : true,
			pagination: false,
			arrows    : false,
			autoplay: true,
		}).mount();
	}
}
export function setupBannerSlidingDoor(){
	const banner_imgs = document.querySelectorAll(".block-banner-sliding-door .banner-image");
	
	banner_imgs.forEach((banner_img) => {
		banner_img.addEventListener("mouseover", ()=>{
			banner_imgs.forEach((_banner_img) => {
				_banner_img.classList.remove("active");
			});
			banner_img.classList.add("active");
		});
	});

}

export function setupBurgerMenu(){
	const burger_menu = document.querySelector(".burger-menu");
	const burger_btn = document.querySelector(".burger-button");
	if (burger_menu && burger_btn){
		const burger_animate = () =>{
			burger_menu.classList.toggle("active");

			if (burger_menu.classList.contains("active")){
				const links = document.querySelectorAll(".burger-menu a");
				let space = 105;
				let space_ctr = 0;
				links.forEach( link => {
					space_ctr += space;
					link.style.top = "-" + space_ctr + "%";
				});
			}else{
				const links = document.querySelectorAll(".burger-menu a");
				links.forEach( link => {
					link.style.top = "0%";
				});
			}
		};

		burger_btn.addEventListener("click", (e) => {
			burger_animate();
			e.preventDefault();
			e.stopPropagation();
		});
		burger_menu.addEventListener("click", () => {
			burger_animate();
		});
	}
}
document.addEventListener("lazybeforeunveil", lazyBackgrounds);


